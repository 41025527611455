import PageWrapper from '@/components/page/wrapper';
import currencyFormat from '@/helpers/currencyFormat';
import { getHAGenerateInvoicesTotalAmount } from '@/pages/dashboard/commerce/components/sharedActionUtils';
import ClientMultiPaymentAmount from '@/pages/dashboard/commerce/multiPayments/clientMultiPaymentAmount';
import { useFetchCloverSurcharge } from '@/pages/settings/cards';
import { useModalControls } from '@/providers/modal';
import { GatewayBase, HouseAccount, Order, Payment } from '@/types/schema';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { IconButton, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { isEmpty, startCase } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentAmount from './amount';
import PaymentDetails from './details';
import PaymentMethod from './method';
import TotalSummary from './totalSummary';

export default function MultiPaymentsDrawer( {
	orders,
	houseAccount,
	clearRows,
}: {
	orders: Order[],
	houseAccount?: HouseAccount,
	clearRows?: () => void
} ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const router = useRouter();
	
	const [ step, setStep ] = useState( 0 );
	const [ method, setMethod ] = useState<string>( '' );
	const [ selectedClientPayment, setSelectedClientPayment ] = useState<Payment | null>( null );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	const [ cardId, setCardId ] = useState<string>( '' );
	const [ cardType, setCardType ] = useState( '' );
	const [ amount, setAmount ] = useState( 0 );
	const [ tipPercent, setTipPercentPercent ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ cardFee, setCardFee ] = useState( 0 );
	const [ finalPayingWithSurcharge, setFinalPayingWithSurcharge ] = useState( 0 );
	
	const route = router.route.split( '/' )[ 1 ];
	const clientPage = route === 'p' || route === 'client';
	
	const invoice = orders?.[ 0 ];
	const total = orders?.reduce( ( sum, order ) => sum + order.grandTotal, 0 );
	const totalRemaining = houseAccount
		? getHAGenerateInvoicesTotalAmount( orders )
		: orders?.reduce( ( sum, order ) => sum + ( order.grandTotal - ( order.paidTotal || 0 ) ), 0 );
	
	const secondary = invoice?.houseAccount?.name || invoice?.houseAccount?.email || invoice?.client?.name || invoice?.client?.email || invoice?.client?.contact || '';
	const invoiceWithGateway = orders?.find( ( order ) => order?.gateway || order?.companyLocation?.gateway );
	const gateway = invoiceWithGateway?.gateway || invoiceWithGateway?.companyLocation?.gateway;
	const cloverGateway = gateway?.external === 'CLOVER' ? gateway : null;
	
	useFetchCloverSurcharge( cloverGateway?.id );
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			primary='Pay'
			secondary={secondary}
			boxProps={{ sx: { width: { md: 700 } } }}
			actions={(
				<IconButton
					size='small'
					onClick={closeModal}>
					<CloseRoundedIcon/>
				</IconButton>
			)}>
			<TotalSummary forHA={Boolean( houseAccount )} totalRemaining={totalRemaining} total={total} invoices={orders}/>
			{!invoice?.id ? (
				<Typography p={2} color='text.secondary'>No invoices for payments</Typography>
			) : (
				<Stepper
					orientation='vertical'
					activeStep={step}
					sx={{ '.MuiPaper-root': { bgcolor: 'background.default' } }}>
					<Step key={0}>
						<StepLabel>
							<Typography variant='h5'>
								{step <= 0
									? t( 'common:select-payment-method' )
									: t( 'commerce:payment-method', {
										method: !method.includes( 'saved-' )
											? startCase( method )
											: t( 'common:saved-card-label' ),
									} )}
							</Typography>
						</StepLabel>
						<StepContent>
							<PaymentMethod
								required={clientPage}
								cardType={cardType}
								setCardType={setCardType}
								invoice={invoice}
								totalRemaining={totalRemaining}
								selectedClientPayment={selectedClientPayment}
								setSelectedClientPayment={setSelectedClientPayment}
								confirmMethod={( { paymentMethod, paymentGateway, cardId, clientPaymentAmount } ) => {
									setPaymentGateway( paymentGateway );
									setMethod( paymentMethod );
									setCardId( cardId );
									setAmount( clientPaymentAmount );
									setStep( 1 );
								}}
							/>
						</StepContent>
					</Step>
					{( houseAccount || !isEmpty( selectedClientPayment ) ) && (
						<Step key={1}>
							{houseAccount
								? (
									<Fragment>
										<StepLabel>
											<Typography variant='h5'>
												{step <= 1
													? t( 'common:payment-amount' )
													: `${t( 'common:payment' )}: ${currencyFormat( finalPayingWithSurcharge )}`}
											</Typography>
										</StepLabel>
										<StepContent TransitionProps={{ unmountOnExit: false }}>
											<PaymentAmount
												amount={totalRemaining}
												invoice={invoice}
												method={method}
												cardType={cardType}
												cancelAmount={() => setStep( 0 )}
												confirmTotal={( {
													paying,
													tipPercent,
													dollarTip,
													cardFeeAmount,
													finalPayingWithSurcharge,
												} ) => {
													setAmount( paying );
													setTipPercentPercent( tipPercent );
													setDollarTip( dollarTip );
													setStep( 2 );
													setCardFee( cardFeeAmount );
													setFinalPayingWithSurcharge( finalPayingWithSurcharge );
												}}
											/>
										</StepContent>
									</Fragment>
								) : (
									<Fragment>
										<StepLabel>
											<Typography variant='h5'>
												{step <= 1
													? t( 'common:payment-amount' )
													: `${t( 'common:payment' )}: ${currencyFormat( finalPayingWithSurcharge )}`}
											</Typography>
										</StepLabel>
										<StepContent TransitionProps={{ unmountOnExit: false }}>
											<ClientMultiPaymentAmount
												total={total}
												totalRemaining={totalRemaining}
												clientPaymentAmount={selectedClientPayment?.amount}
												cancelAmount={() => {
													setStep( 0 );
													setSelectedClientPayment( null );
												}}
												confirmTotal={( { totalPaying } ) => {
													setAmount( totalPaying );
													setStep( 2 );
												}}
											/>
										</StepContent>
									</Fragment>
								)}
						</Step>
					)}
					<Step key={houseAccount || !isEmpty( selectedClientPayment ) ? 2 : 1}>
						<StepLabel>
							<Typography variant='h5'>{t( 'common:confirm' )}</Typography>
						</StepLabel>
						<StepContent>
							<PaymentDetails
								invoices={orders}
								cardFee={cardFee}
								method={selectedClientPayment?.type || method}
								cardType={cardType}
								amount={houseAccount && amount}
								houseAccount={houseAccount}
								cardToken={cardId}
								tip={tipPercent}
								dollarTip={dollarTip}
								paymentGateway={paymentGateway}
								selectedClientPayment={selectedClientPayment}
								cancel={() => setStep( houseAccount || !isEmpty( selectedClientPayment ) ? 1 : 0 )}
								confirm={( payment ) => {
									if ( payment ) {
										enqueueSnackbar( t( 'common:payment-complete' ), { variant: 'success' } );
										closeModal();
									}
									clearRows?.();
								}}
							/>
						</StepContent>
					</Step>
				</Stepper>
			)}
		</PageWrapper>
	);
}
