import { useCloverLocations } from '@/pages/formSelects/locationSelect';
import { useMenu } from '@/providers/menu';
import { Location } from '@/types/schema';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { MenuItem, MenuList } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';

export function useLocationSelectMenu() {
	const { showMenu } = useMenu();
	const [ locations ] = useCloverLocations( false, { options: { limit: 20 } } );
	const [ location, setLocation ] = useState<Location | undefined>( undefined );
	
	return {
		location,
		action:
			!isEmpty( locations ) && {
				name   : location
					? location?.name || location?.address?.line1 || ''
					: 'All Locations',
				icon   : <LocationOnIcon/>,
				onClick: ( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ p: 0 }}>
						{[ { id: '12', name: 'All Locations', address: null } as Location,
						   ...locations ].map( ( dbLocation, index ) => (
							<MenuItem
								key={index}
								selected={location?.id === dbLocation.id}
								onClick={() => {
									setLocation( dbLocation.name === 'All Locations' ? undefined : dbLocation );
									closeMenu();
								}}>
								{dbLocation.name || dbLocation?.address?.line1 || ''}
							</MenuItem>
						) )}
					</MenuList>
				), e.currentTarget ),
			},
		
	};
}

export default function useOrdersExtraActions() {
	const { location, action } = useLocationSelectMenu();
	return {
		variables: { location },
		actions  : [ action ].filter( Boolean ),
	};
	
}

