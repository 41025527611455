import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import currencyFormat from '@/helpers/currencyFormat';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function ClientMultiPaymentAmount( {
	cancelAmount,
	confirmTotal,
	clientPaymentAmount,
	total,
	totalRemaining,
}: {
	cancelAmount,
	confirmTotal,
	clientPaymentAmount: number,
	total: number,
	totalRemaining: number
} ) {
	const { t } = useTranslation();
	
	const paying = clientPaymentAmount > totalRemaining ? totalRemaining : clientPaymentAmount;
	
	return (
		<Fragment>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:total' )}/>
				<Typography style={{ fontSize: 18 }}>
					{currencyFormat( total )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:remaining' )}/>
				<Typography color='warning.main' style={{ fontSize: 18 }}>
					{currencyFormat( totalRemaining )}
				</Typography>
			</ListItem>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:paying' )}/>
				<Typography variant='h3'>
					{currencyFormat( paying )}
				</Typography>
			</ListItem>
			<Stack spacing={2} direction='row' alignItems='center' mt={2}>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<ArrowBackIosIcon/>}
					onClick={cancelAmount}>
					Back
				</AsyncLoadingButton>
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={!paying}
					onClick={async () => confirmTotal( {
						totalPaying: paying,
					} )}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
