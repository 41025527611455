import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import currencyFormat from '@/helpers/currencyFormat';
import Tips from '@/pages/dashboard/commerce/payment/tip';
import { surchargeFeeAtom, useFetchCloverTips } from '@/pages/settings/cards';
import type { Order } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Divider,
	Grid,
	InputAdornment,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { clamp, isEmpty, round } from 'lodash-es';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCardFeeAmount } from '../payment/helpers';

export default function PaymentAmount( { cancelAmount, confirmTotal, method, cardType, invoice, amount }: {
	cancelAmount,
	confirmTotal,
	method: string,
	cardType: string,
	invoice: Order,
	amount: number
} ) {
	
	const [ tipPercent, setTipPercent ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ showTipOptions, setShowTipOptions ] = useState( true );
	const [ paymentAmount, setPaymentAmount ] = useState( 0 );
	const [ selectedTipOption, setSelectedTipOption ] = useState( 4 );
	
	const [ select, setSelect ] = useState( 'full' );
	
	const { t } = useTranslation();
	const cloverGateway = invoice?.gateway?.external === 'CLOVER' && invoice?.gateway;
	const [ loading, cloverTips ] = useFetchCloverTips( cloverGateway?.id );
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent || 0
		: 0;
	const cardFee = surchargePercent > 0 ? 0 : getCardFeeAmount( invoice, method, cardType, true );
	const finalRemaining = amount;
	
	const paying = useMemo( () => {
		
		switch ( select ) {
			case '$':
				return round( clamp( paymentAmount, 0, finalRemaining ), 2 );
			case '%':
				return round( clamp( finalRemaining * paymentAmount / 100, 0, finalRemaining ), 2 );
			case 'full':
				return round( finalRemaining, 2 );
		}
		
	}, [ select, paymentAmount, finalRemaining ] );
	const cardFeeAmount = paying * cardFee;
	const finalPaying = paying + cardFeeAmount;
	
	const fullPartialSelectContent = useCallback( () => (
		<Stack spacing={1}>
			<Stack direction='row' alignItems='center' spacing={1}>
				<Select
					sx={{ width: 100 }}
					value={select}
					size='small'
					onChange={( e ) => setSelect( e.target.value )}>
					<MenuItem value='$'>$</MenuItem>
					<MenuItem value='%'>%</MenuItem>
					<MenuItem value='full'>{t( 'commerce:full' )}</MenuItem>
				</Select>
				<FormattedTextField
					fullWidth
					disabled={select === 'full'}
					value={select === 'full' ? currencyFormat( finalRemaining ) : paymentAmount}
					variant='outlined'
					placeholder={t( 'common:amount' )}
					onChange={( e ) => setPaymentAmount( +e.target.value || 0 )}
					onFocus={( e ) => e.target.select()}
				/>
			</Stack>
			<Divider sx={{ mt: 2 }}/>
		</Stack>
	), [ select, paymentAmount, finalRemaining ] );
	
	const renderAmountContent = () => {
		// if invoice metatdta has enablePartialPayment, use that value, otherwise use company metadata
		const enablePartialPayment = invoice.metadata.hasOwnProperty( 'enablePartialPayment' )
			? !invoice.metadata?.enablePartialPayment
			: !invoice.company?.metadata?.enablePartialPayment;
		
		if ( enablePartialPayment ) {
			return fullPartialSelectContent();
		}
		
		return null;
	};
	
	const showTips = invoice.company.metadata?.hideTips ? false : invoice.metadata.hasOwnProperty( 'hideTips' )
		? !invoice.metadata?.hideTips
		: !invoice.company.metadata?.hideTips;
	
	const finalPayingWithSurcharge = finalPaying + finalPaying * ( surchargePercent / 100 ) + ( dollarTip || tipPercent * paying / 100 );
	
	return (
		<Fragment>
			{renderAmountContent()}
			{surchargePercent > 0 ? (
				<ListItem disableGutters>
					<ListItemText primary={`There is a surcharge of ${surchargePercent}%`}/>
					<Typography>
						{currencyFormat( finalPaying * ( surchargePercent / 100 ) )}
					</Typography>
				</ListItem>
			) : null}
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:total' )}/>
				<Typography variant='h3'>
					{currencyFormat( finalRemaining )}
				</Typography>
			</ListItem>
			{surchargePercent <= 0 && cardFee > 0 ? (
				<ListItem disableGutters>
					<ListItemText primary='Card Fee'/>
					<Typography variant='h3'>
						{currencyFormat( cardFeeAmount )}
					</Typography>
				</ListItem>
			) : null}
			{showTips && (
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:tip' )}/>
					<Typography variant='h3' color='success.main'>
						{currencyFormat( dollarTip || tipPercent * paying / 100 )}
					</Typography>
				</ListItem>
			)}
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:paying' )}/>
				<Typography variant='h3'>
					{currencyFormat( finalPayingWithSurcharge )}
				</Typography>
			</ListItem>
			{paying !== finalRemaining && (
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:remaining' )}/>
					<Typography variant='h3' color='warning.main'>
						{finalRemaining - paying > 0 ? currencyFormat( finalRemaining - paying ) : '$0.00'}
					</Typography>
				</ListItem>
			)}
			{showTips && (
				<Grid container spacing={1}>
					{showTipOptions ? cloverGateway?.id && loading
						? <Loading/>
						: !isEmpty( cloverTips )
							? (
								<Tips
									type='Clover'
									cloverTips={cloverTips}
									selectedTipOption={selectedTipOption}
									setSelectedTipOption={setSelectedTipOption}
									setTip={setTipPercent}
									setDollarTip={setDollarTip}
									paying={paying}
								/>
							)
							: (
								<Tips
									type='Invoiss'
									selectedTipOption={selectedTipOption}
									setSelectedTipOption={setSelectedTipOption}
									setTip={setTipPercent}
									setDollarTip={setDollarTip}
									paying={paying}
								/>
							) : (
								<Grid container item spacing={1}>
									<Grid item xs={6}>
										<FormattedTextField
									fullWidth
									type='number'
									size='medium'
									InputProps={{ startAdornment: <InputAdornment position='start'>%</InputAdornment> }}
									value={tipPercent}
									sx={{ '.MuiInputLabel-root': { fontSize: '1.5rem !important' } }}
									onChange={( e ) => {
										setTipPercent( +e.target.value );
										setDollarTip( 0 );
									}}
									onFocus={( e ) => e.target.select()}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormattedTextField
									fullWidth
									type='number'
									size='medium'
									InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
									value={dollarTip}
									sx={{ '.MuiInputLabel-root': { fontSize: '1.5rem !important' } }}
									onChange={( e ) => {
										setDollarTip( +e.target.value );
										setTipPercent( 0 );
									}}
									onFocus={( e ) => e.target.select()}
										/>
									</Grid>
								</Grid>
					)}
					<Grid item xs={12}>
						<Card>
							<CardActionArea
								onClick={() => {
									setTipPercent( 0 );
									setDollarTip( 0 );
									setShowTipOptions( !showTipOptions );
									setSelectedTipOption( null );
								}}>
								<CardContent>
									<Typography variant='h5' textAlign='center'>
										{showTipOptions ? t( 'common:custom-tip' ) : t( 'common:tip-option' )}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card
							sx={selectedTipOption === 4 ? {
								border     : 2,
								borderColor: 'primary.main',
								color      : 'primary.main',
							} : {
								border     : 2,
								borderColor: 'divider',
							}}>
							<CardActionArea
								onClick={() => {
									setTipPercent( 0 );
									setDollarTip( 0 );
									setSelectedTipOption( 4 );
								}}>
								<CardContent>
									<Typography variant='h5' textAlign='center'>
										{t( 'common:no-tip' )}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				</Grid>
			)}
			<Stack spacing={2} direction='row' alignItems='center' mt={2}>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<ArrowBackIosIcon/>}
					onClick={cancelAmount}>
					Back
				</AsyncLoadingButton>
				<Button
					variant='contained'
					color='primary'
					disabled={!finalRemaining || !paying}
					onClick={async () => confirmTotal( {
						paying,
						tipPercent,
						dollarTip,
						cardFeeAmount,
						finalPayingWithSurcharge,
					} )}>
					Continue
				</Button>
			</Stack>
		</Fragment>
	);
}
